import environment from 'config/environment';
import {
  type FC,
  useEffect,
  useRef } from 'react';
import { isLandscape } from 'services/mobile';

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    AdobeDC: {
      View: new (
        options:
        {
          clientId: string,
          divId: string,
          downloadWithCredentials: boolean,
        }
      ) => {
        previewFile: (
          file: {
            content: { location: { url: string, }, }, metaData: { fileName: string, },
          },
          options: {
            defaultViewMode: string,
            embedMode?: string,
            showAnnotationTools?: boolean,
            showDownloadPDF?: boolean,
            showFileName?: boolean,
            showFullScreen?: boolean,
          }
        ) => void,
      },
    };
  }
}

type PdfReaderProps = {
  url?: string,
};

const PdfReader: FC<PdfReaderProps> = ({ url }) => {
  const viewerRef = useRef<HTMLDivElement>(null);
  const alreadyLoadedRef = useRef(false);

  useEffect(() => {
    const loadPdf = () => {
      if (!alreadyLoadedRef.current && viewerRef.current && url && window.AdobeDC) {
        alreadyLoadedRef.current = true;
        const adobeDCView = new window.AdobeDC.View({
          clientId: environment.adobePdfEmbedApiKey,
          divId: viewerRef.current.id,
          downloadWithCredentials: true,
        });

        adobeDCView.previewFile(
          {
            content: { location: { url } },
            metaData: { fileName: ' ' },
          },
          {
            defaultViewMode: isLandscape() ? '' : 'SINGLE_PAGE',
            showAnnotationTools: false,
            showFileName: false,
          },
        );
      }
    };

    const handleAdobeSDKReady = () => {
      document.removeEventListener('adobe_dc_view_sdk.ready', handleAdobeSDKReady);
      loadPdf();
    };

    const loadAdobeScript = () => {
      const script = document.createElement('script');
      script.src = 'https://acrobatservices.adobe.com/view-sdk/viewer.js';
      script.async = true;
      script.onload = () => {
        document.addEventListener('adobe_dc_view_sdk.ready', handleAdobeSDKReady);
      };

      document.body.appendChild(script);
    };

    if (window.AdobeDC) {
      loadPdf();
    } else {
      loadAdobeScript();
    }

    return () => {
      document.removeEventListener('adobe_dc_view_sdk.ready', handleAdobeSDKReady);
    };
  }, [
    url,
  ]);

  return (
    <div
      id='adobe-dc-view'
      ref={viewerRef}
      style={{
        height: '100%',
        width: '100%',
      }}
    />
  );
};

export { PdfReader };
