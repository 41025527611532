import './NestedOrganizationsSelector.scss';
import { notificationsService } from '../../services';
import { FundsAndAccountsColumn } from './FundsAndAccountsColumn';
import { OrganizationsColumn } from './OrganizationColumn';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import { Svgicon } from 'components/Svgicon/Svgicon';
import { FiltersContext } from 'contexts';
import { arraysEqual,
  getPeriodOptions } from 'helpers/helpers';
import {
  type FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  isMobile } from 'services/mobile';
import { type AppDispatch } from 'store';
import {
  type OrganizationsStoreState,
  resetDisplayedOrganizations,
} from 'store/slices';
import {
  type Filters,
  type FilterStoreState,
  setUsualDocumentsFilters,
} from 'store/slices/filtersSlice';
import { setActiveFilters } from 'store/slices/filtersSlice';
import { type NestedOrganization } from 'types';

export const NestedOrganizationsSelector: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { organizations,
    displayedOrganization,
    displayedOrganizationWithPermissions,
    organizationsPending } = useSelector<{
    organizations: OrganizationsStoreState,
  }>((state) => state.organizations) as OrganizationsStoreState;

  const [
    showSelector,
    setShowSelector,
  ] = useState(false);

  const activeFilters = useSelector<{
    filters: FilterStoreState,
  }>((state) => state.filters.activeFilters) as Filters | null;

  const componentRef = useRef(
    null,
  ) as unknown as React.MutableRefObject<HTMLDivElement>;
  const [
    mobile,
    setMobile,
  ] = useState(isMobile());
  const [
    disabled,
    setDisabled,
  ] = useState(false);
  const [
    showScndPage,
    setShowScndPage,
  ] = useState(!mobile || organizations?.length === 1);
  const { setFirmId } = useContext(FiltersContext);

  window.onresize = () => {
    setMobile(isMobile());
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const closeAndResetFilters = () => {
    setShowSelector(false);
    dispatch(resetDisplayedOrganizations(activeFilters as Filters));
  };

  const shouldSkipUpdate = (accountIds: string[]) =>
    arraysEqual(activeFilters?.accountIds as string[], accountIds);

  const applyFetchSelection = () => {
    setShowSelector(false);
    if (!activeFilters) {
      return;
    }

    const accounts = displayedOrganization?.funds.flatMap((fund) => fund.accounts) ?? [];
    const accountIds = accounts.map((acc) => acc.accountId);

    if (shouldSkipUpdate(accountIds)) {
      return;
    }

    if (!activeFilters.accountIds.length || !activeFilters.fundIds.length) {
      notificationsService.error('Please select accounts or funds!');
      return;
    }

    if (displayedOrganization) {
      const allAccountsIds = displayedOrganization.funds
        .flatMap((fund) => fund.accounts).map((acc) => acc.accountId);

      dispatch(
        setActiveFilters({
          accountIds: allAccountsIds,
          fundIds: displayedOrganization.funds.map((fund) => fund.fundId),
          organizationId: displayedOrganization.organizationId,
        }),
      );

      dispatch(
        setUsualDocumentsFilters({
          quarters: getPeriodOptions(
            displayedOrganizationWithPermissions as NestedOrganization)
            .flatMap((opt) => opt.children?.map((item) => item.id)) as string[],
          years: getPeriodOptions(displayedOrganization).map((opt) => opt.id),
        }),
      );
    }

    setFirmId(activeFilters.organizationId);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClickOutside = (event: MouseEvent) => {
    if (
      showSelector && !mobile &&
      componentRef.current &&
      !componentRef.current.contains(event.target as Node)
    ) {
      closeAndResetFilters();
    }
  };

  useEffect(() => {
    // Add a click event listener to the document
    document.addEventListener('click', handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener('click', handleClickOutside);
    };
  }, [
    handleClickOutside,
  ]);

  useEffect(() => {
    if (activeFilters
      && activeFilters?.organizationId.length < 2
      && activeFilters.accountIds.length < 2
      && activeFilters.fundIds.length < 2) {
      setDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const firmContainer = document.querySelector('.firmContainer') as HTMLElement;
    if (!firmContainer) {
      return;
    }

    if (showSelector) {
      firmContainer.classList.add('fixed');
    } else {
      firmContainer.classList.remove('fixed');
    }

    // eslint-disable-next-line consistent-return
    return () => {
      firmContainer.classList.remove('fixed');
    };
  }, [
    showSelector,
  ]);

  const toggleSelector = (event?: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    if (disabled || organizationsPending) {
      return;
    }

    if (showSelector) {
      applyFetchSelection();
    }

    setShowSelector(!showSelector);
    setShowScndPage(!mobile || organizations?.length === 1);
  };

  return (
    <div className='relative flex w-full  items-center gap-0.625'>
      <div
        className='orglogo-container'
        data-test={'org-logo'}
        onClick={toggleSelector}
      >
        <img
          alt='orgLogo'
          className='relative max-h-full max-w-full object-cover'
          src={displayedOrganization?.organizationLogo}
        />
      </div>
      {showSelector && !mobile &&
        <div
          className='orglogo-container upper'
          onClick={toggleSelector}
        >
          <img
            alt='orgLogo'
            className='max-h-full max-w-full'
            src={displayedOrganization?.organizationLogo}
          />
        </div>
      }
      <Button
        className={`!px-0.5 !text-font-1 ${mobile && '!inline w-full landscape:!h-2 landscape:w-auto landscape:!rounded-large'}`}
        data-test='filters-selector-modal'
        onClick={toggleSelector}
        size={mobile ? 'large' : 'medium'}
        squared={mobile}
        tooltipWrapperClass='!justify-start'
        type='grey'
      >
        <div className='button-fund-selector'>
          <span>{displayedOrganization?.organizationName}</span>
          <span className='mobile-hide'>•</span>
          <div className='flex items-center gap-0.75'>
            <div className='flex items-center gap-0.375 !font-normal'>
              <Svgicon className='size-1' id='luggage-04' />
              <span>{`${activeFilters?.fundIds.length} funds`}</span>
            </div>
            <div className='flex items-center gap-0.375 !font-normal'>
              <Svgicon className='size-1' id='user-profile-03' />
              {`${activeFilters?.accountIds.length} accounts`}
            </div>
            <Svgicon className='mobile-hide size-1' id='UP-arrow-down' />
          </div>
        </div>
      </Button>
      {showSelector &&
        <div className='selector-container'>
          <div className='main-white-card selector z-40 max-w-[50rem] wide-table:max-w-[70rem]' ref={componentRef}>
            <div className='relative flex h-full flex-col gap-[0.5rem] p-[0.78125rem]'>
              <div className='selector-columns'>
                <OrganizationsColumn showScndPage={(show) => setShowScndPage(show)} />
                <div className='vertical-line' />
                {showScndPage && <FundsAndAccountsColumn />}
              </div>
              {(!mobile || showScndPage) &&
                <div className={`${mobile && 'absolute bottom-0 left-0 p-[0.78125rem]'} flex w-full justify-center gap-[0.25rem] self-end ms:w-fit`}>
                  {!mobile && <Button
                    className=' w-full ms:w-[6.5rem]'
                    data-test='close-button'
                    onClick={closeAndResetFilters}
                    size={mobile ? 'large' : 'medium'}
                    squared
                    text='Close'
                    type='light-main'
                  />}
                  <Button
                    className='w-full ms:w-[6.5rem] landscape:!h-2'
                    data-test='apply-button'
                    disabled={!activeFilters?.accountIds.length
                      || !displayedOrganization?.funds.length}
                    isLoading={organizationsPending}
                    onClick={applyFetchSelection}
                    size={mobile ? 'large' : 'medium'}
                    squared
                    text='Apply'
                  />
                </div>}
            </div>
            {mobile &&
              <div
                className='absolute right-1 top-[1rem] size-1.75 cursor-pointer landscape:hidden'
                onClick={closeAndResetFilters}
              >
                <Svgicon id='menu-close' />
              </div>}
          </div>
        </div>
      }
    </div>
  );
};
