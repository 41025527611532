import { Helmet } from 'react-helmet';

export const SCRIPTS = {
  googleAnalyticsTagScript: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-VTZYEJVF3W');
    `,
  googleAnalyticsTagSrc: 'https://www.googletagmanager.com/gtag/js?id=G-VTZYEJVF3W',
};

/**
 * Append tag to head by React Helmet
 *
 * @param text
 */
export const insertJsScriptText = (text: string) => {
  return (
    <Helmet>
      <script>{text}</script>
    </Helmet>
  );
};

export const insertJsScriptSource = (source: string) => {
  return (
    <Helmet>
      <script async src={source} />
    </Helmet>
  );
};
