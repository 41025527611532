import ChevronRight from '../../../assets/icons/global/firmsNextPage.svg?react';
import fallbackImage from '../../../assets/images/empty-firm.png';
import style from './FirmItem.module.scss';
import {
  type FC,
  type MouseEventHandler,
} from 'react';

type FirmItemProps = {
  logo: string,
  name: string,
  onSelect: () => void,
};

export const FirmItem: FC<FirmItemProps> = ({
  name,
  logo,
  onSelect,
}) => {
  const onClick: MouseEventHandler<HTMLDivElement> = () => onSelect();

  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  return <div className={style.firmItem} data-name='firm-item' onClick={onClick} title={name}>
    <div className={style.content}>
      <div className={style.logoContainer}>
        <div className={style.logo}>
          <img
            alt='Logo'
            className={style.logoImage}
            onError={(error) => {
              const target = error.target as HTMLImageElement;
              target.onerror = null;
              target.src = fallbackImage;
            }}
            src={logo}
          />
        </div>
      </div>
      <div className={style.header}>
        <span className='w-full truncate'>{name}</span>
      </div>
    </div>
    <div className={style.nextPageContainer}>
      <ChevronRight />
    </div>
  </div>;
};
