import { isProduction } from './environment';

export const sendLoginEvent = () => {
  if (isProduction()) {
    window.gtag('event', 'login', {method: 'Portal App'});
  }
};

export const sendSignupEvent = () => {
  if (isProduction()) {
    window.gtag('event', 'sign_up', {method: 'Portal App'});
  }
};
