/* eslint-disable max-len */
import classNames from 'classnames';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import { Svgicon } from 'components/Svgicon/Svgicon';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'services/mobile';

const redirectToUrl = (newUrl: boolean) => {
  const newWindow = window.open('', '_blank');
  const url = newUrl ? 'https://portal.orcainc.com/' : 'https://app.servc.co.il/';
  if (newWindow) {
    newWindow.location.href = url;
  }
};

const isOldDomain = window.location.hostname.includes('servc.co.il');

export const MovementNotification = () => {
  const [
    showDescription,
    setShowDescription,
  ] = useState(!isMobile());
  const navigate = useNavigate();
  const navigateToResetPassword = () => {
    sessionStorage.setItem('canResetPassword', 'true');
    navigate('/auth/forgot-password');
  };

  const title =
    <>Portal has moved to <span className='cursor-pointer' onClick={() => redirectToUrl(true)}><u>portal.orcainc.com</u>!</span></>;
  const description =
    <>
      Your credentials stay the same. Should you experience trouble logging in, you can <span className='cursor-pointer' onClick={navigateToResetPassword}><u>reset your password</u></span> or contact us at <a className='text-white-999 underline' href='mailto:helpdesk@orcainc.com'>helpdesk@orcainc.com</a>.
    </>;

  const containerClasses = classNames(
    'w-full rounded-main bg-main-700 lg:text-font-1 bg-[length:7em] bg-[105%_1em] xxl:text-title-3 pl-[1rem] pr-[1.25rem] py-[1.25rem] dt:p-[1.25rem] dt:bg-[url(/src/assets/images/browser.webp)] bg-no-repeat',
    {
      '!bg-right-bottom bg-[url(/src/assets/images/browser-transparent.svg)]': showDescription && isMobile(),
      '!p-[0.5rem] !h-[2.5rem] lg:!h-[2rem] flex justify-center items-center !self-center !bg-none': !showDescription,
    },
  );

  const closeNotification = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowDescription(false);
  };

  return <div className={containerClasses} onClick={() => setShowDescription(true)}>
    <div className='relative flex w-full items-start gap-1 dt:gap-1.5'>
      <div className='flex w-[30em] flex-col items-start gap-0.75'>
        <div className='flex w-full flex-col gap-0.75'>
          <div className='flex w-full items-center justify-between gap-2'>
            {showDescription ?
              <p className='m-0 text-font-1 font-bold text-white-999'>{title}</p>
              :
              <div className='flex items-center gap-0.5'>
                <Svgicon className='size-0.875' color='white' id='browser' />
                <p className='m-0 text-[0.8125rem] font-medium text-white-999 dt:text-font-2'>{title}</p>
              </div>
            }
            {showDescription ?
              <div
                className='absolute -right-1 -top-1 cursor-pointer'
                onClick={closeNotification}
              >
                <Svgicon className='size-1.5 text-white-999' id='close-1f' />
              </div>
              :
              <div className='absolute -top-0.125 right-0'>
                <Svgicon className='size-1.25 rotate-90 text-white-999' id='arrow-right-3f' />
              </div>
            }
          </div>
          {showDescription &&
          <p className='m-0 text-font-3 font-normal text-white-999'>
            {description}
          </p>
          }
        </div>
        {showDescription && isOldDomain &&
        <Button
          className='cursor-pointer !rounded-main !text-main-700'
          onClick={() => redirectToUrl(true)}
          text='Go to portal.orcainc.com'
          tooltipWrapperClass='!w-auto'
          type='white'
        />
        }
      </div>
    </div>
  </div>;
};
